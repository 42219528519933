import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "ジョブメドレー SRE Unit の業務を円滑に行う取り組みについて",
  "date": "2023-08-31T07:43:52.000Z",
  "slug": "entry/2023/08/31/164352",
  "tags": [],
  "hero": "./2023_08_31.png",
  "heroAlt": null
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`はじめに`}</h1>
    <p>{`こんにちは。
人材プラットフォーム本部プロダクト開発室第一開発グループの阪本です。`}</p>
    <p>{`毎日夕方になっても 30 度を超えるような猛暑が続く中、皆さんはいかがお過ごしでしょうか？`}</p>
    <p>{`今まで何度も紹介させていただきましたが、私は阪神タイガースのファンです。
今年は第二次岡田政権の 1 年目、開幕からの好調を維持し交流戦までは首位独走。交流戦明けに一時的に首位を明け渡す事態になりましたが、8 月に入り破竹の 2 桁連勝で首位奪還どころか独走状態という最高のシーズンを迎えております。`}</p>
    <p>{`投手は元々良いチームではありましたが、今年は課題であった守備と出塁率に改善が見られ `}<strong parentName="p">{`普通にやれば勝てる`}</strong>{` チームに近づいたというのが大きいのではないでしょうか。`}</p>
    <p>{`この記事が公開される頃には優勝マジックが点灯し、18 年振りの「あれ」まで秒読みとなっていることでしょう。待ちに待ったその瞬間まで、あと少しです。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/4cfd27b65b0496ab4b142175f84fe667/71254/_dev202308_000a.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAEDBP/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAGjz0RCD//EABkQAAIDAQAAAAAAAAAAAAAAAAACARESIv/aAAgBAQABBQLaMUSKhlb4P//EABYRAQEBAAAAAAAAAAAAAAAAAAAREv/aAAgBAwEBPwHKP//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/Aar/xAAaEAACAwEBAAAAAAAAAAAAAAAAAREhMQIQ/9oACAEBAAY/AtQoLbL5UGKPP//EAB4QAQACAgEFAAAAAAAAAAAAAAEAESExcUFhgZGh/9oACAEBAAE/IQDu5ll2d7ibwOGAvwCVZeBA6w9T/9oADAMBAAIAAwAAABDkL//EABcRAQADAAAAAAAAAAAAAAAAAAABEXH/2gAIAQMBAT8Qmzb/xAAXEQEBAQEAAAAAAAAAAAAAAAABABFR/9oACAECAQE/EBlrl//EABwQAQEAAgIDAAAAAAAAAAAAAAERACExQVGhsf/aAAgBAQABPxAeAQxg85UiYqBxjNwNbImIu1noysn6Tf5hiJe4s//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": " dev202308 000a",
            "title": " dev202308 000a",
            "src": "/static/4cfd27b65b0496ab4b142175f84fe667/e5166/_dev202308_000a.jpg",
            "srcSet": ["/static/4cfd27b65b0496ab4b142175f84fe667/f93b5/_dev202308_000a.jpg 300w", "/static/4cfd27b65b0496ab4b142175f84fe667/b4294/_dev202308_000a.jpg 600w", "/static/4cfd27b65b0496ab4b142175f84fe667/e5166/_dev202308_000a.jpg 1200w", "/static/4cfd27b65b0496ab4b142175f84fe667/d9c39/_dev202308_000a.jpg 1800w", "/static/4cfd27b65b0496ab4b142175f84fe667/df51d/_dev202308_000a.jpg 2400w", "/static/4cfd27b65b0496ab4b142175f84fe667/71254/_dev202308_000a.jpg 4080w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`さて、現在私は SRE Unit リーダの立場で主に`}<a parentName="p" {...{
        "href": "https://job-medley.com/"
      }}>{`ジョブメドレー`}</a>{`の安定稼働に向けた取り組みに日々取り組んでいます。
今回はチーム内で抱える課題と、それを解決するための取り組みについて紹介させていただきます。`}</p>
    <h1>{`SRE Unit が取り組んでいる課題`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/aa6731ece54c9609c82dfdf4916f7282/9239a/_dev202308_001a.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "87.00000000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAIAAABSJhvpAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB2UlEQVQ4y42TS4/aMBSF+e3talbzR7pGqmbTRR/SzKIVlBYUMokJhMTOC2PjR66vqyQwSlGZ6dnFzudzz732xI90Op3CMJzP50EQKKU8+tc1GX+0bds0DWOsqqq2bd9iL7AxhlIqpewNO0ozzhdbBw5vF9DBQogoitI0zXpZY8E7TQ+HnxsHDgDGACI650Yw5+vlsirLYaksyyiKtDHnLADgumKw5yilu93uDIP3qRAhY5UQ2X6f5zn06l3OZh47K+ecdZBlWRiGhBDO+cQul+XdXXV/f3x6qqUsGaO9tNIe3JTwd1/J+2/kQ9g0j+vmS7BP03W4llIi4gSTBKdT//DgCBky7bbbX6vfdLVpPq/yo1qWYlUKqgAOJ1Mcy6ILJaU8Z8ZLgTj0WeuqqrTSCH1jHGCfAhHBY5ZlQRAkSWKtnXjnfNt6AH/pobX2pZ/9WabtBtZnR9RaCyGklAAwuRpda9sgXNNNenqMD99jaMEYM1T0xg3z3td1HRPSsMpsKpkUw5zHMF50DVtr67pWSuHo9yv4H87DdtM0s9msKIqBOTfp7xt2s2yl1HP0TJOUflrkH3+YxR7cTdtrGBFjQljO8KAM404Y/J9XNRwvhIjjmHP+svL6k/wDYDDXL70/3zMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": " dev202308 001a",
            "title": " dev202308 001a",
            "src": "/static/aa6731ece54c9609c82dfdf4916f7282/c1b63/_dev202308_001a.png",
            "srcSet": ["/static/aa6731ece54c9609c82dfdf4916f7282/5a46d/_dev202308_001a.png 300w", "/static/aa6731ece54c9609c82dfdf4916f7282/0a47e/_dev202308_001a.png 600w", "/static/aa6731ece54c9609c82dfdf4916f7282/c1b63/_dev202308_001a.png 1200w", "/static/aa6731ece54c9609c82dfdf4916f7282/9239a/_dev202308_001a.png 1246w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`SRE Unit の業務は`}</p>
    <ul>
      <li parentName="ul">{`運用/メンテナンス`}</li>
      <li parentName="ul">{`障害対応`}</li>
      <li parentName="ul">{`アカウント管理`}</li>
      <li parentName="ul">{`環境整備`}</li>
    </ul>
    <p>{`と多岐に渡り、ジョブメドレーを含めた大小 5 システムを対象として 4 名のメンバーで推進しています。`}</p>
    <p>{`この中でも最近の SRE Unit での業務の大半を占めるものがリソースのバージョン追従対応ですが、明確な期限が存在する上に対象システム/リソースは多いといった特徴があるため、計画的に行動しないと突発業務が発生した際に期限内の対応が困難になります。`}</p>
    <p>{`特に AWS といったマネージドサービス系はアップデートが強制的にスケジューリングされるため、こちら側の都合を挟む余地はありません。
こういった事態にならないために行なっている SRE Unit での `}<strong parentName="p">{`この先に何が起こるのか？`}</strong>{` という「予測/計画」と、`}<strong parentName="p">{`計画を阻害する要因`}</strong>{` となる「突発作業の削減」の 2 軸の取り組みについて紹介します。`}</p>
    <h1>{`バージョン追従対応とは？`}</h1>
    <p>{`まずは前情報としてバージョン追従作業というものがどういったものなのかを説明します。`}</p>
    <p>{`これはシステムが利用しているサーバ/データベースといったリソースのサポート期限終了 (EOL:End Of Life) までに最新バージョンに引き上げることを指します。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "450px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/1c73537891936d1153988ab62f33437b/fc2a6/_dev202308_003.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "81.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEuklEQVQ4y5WUeUzTBxTH38+iVIegPeg41BkXnZNjzqF4tLQFBeYxhNKCKNkwuu0PwSNZdCZGiTqdMUYQBHpRetBCaQuUlIKWQ/FGEMZU3ARtq2PRUigqpbRv6cI/+3Of5P3x/vnk5eW9L4TSqECjLAQalUIsjgwnUSgUCFuyFFZt3UPKNjuBb7ACv95G8BteQabBCoKmEfhfbFi9khoXv44SlX0EBMYRNr/evoXfYIc0wyuira0Tbt7tJm4/eETcfdhP3OvpJ+509xF1xhbiWkcX+MsPMeOiwpx552fPDforKGThyPy55FN5N6c38HQv4tLUzwKCjQgKpRoq5LWg0DZCTYMZ6owtUNtohtwDx6C++TrR1NoOkLONFbB7GxNSmGtOSk/tx4M5qRi/+nOk0WgIACtyuzAjUze0eY/+T7hUpQ++IlaGV1TVRIiV2kWV1bolEqV2aapg71x9Uys0NFsIGDacn9MpKYQ7slPnnR3l+LrlitvVdtWdL0jGBRGL9h18jbG5NQPLnrgQ+p/8cdo57pp0vXv/5v2ke/zDpNv1YXLK/eDRQK9IWRcmqdYBYOc5cLWUwkSzSGgzi9FmkXhGTULPhP4yeq8VVTLbce239UPxDfcHoe/3p8VerxedTifarFZ8MTyMQ8+f44uXVjRZbiTf6+kHmL5RkjJt+XX7VGt5KfbUIT4xTmK3AacsipcXTxzJAIAAgCQQqhuX1ujqr4jEIiwoKPAcPX7cd7Kw0FtQkO+VymT49Ll1Q+/AIICv9Vyfr+WsB8dvRfd1mR7+dqsZ/372EDP27p8IiE93MRKzPEu27fvpQrGw19Jxq/vl42dot9m9jqFhn8Nu942+dXhHhm2obWxpOnGuKAys+rNxgu9/dAczM5tnfZnqiN6Ri3tlN6bCv/4O6ax0+eLknNPs7LxXFWKp2my56cYZRiUyn/vufd9MO6GsMzpKpdW/QElhPkBc+iEGl++MTM29QNu43Th/RwHSUvJwwaadPSb5xeWeOyIcby+92Pbg8Qnn2Bg6nOPTbx2j+ObtqM/hHPOOuSYcGoNJVybTiACxCyA0GegJmbNDOQII37xrBZ218x2NlTFNY6ZjGJe/G9ETgWMayhlVB9nU2r7SdL0zSqSoHZKo6lCla0q7fa+XWqnWLygWyQOAzhFA2JYcgs7iQcjaVDJ14zdAS+CZ6Gwe0ljpSNmUdgyWs4rgk/VrIDoJzNc7oUgoX1Yu03hKxAoslaiaJCrdLv/JyNQGgKC4FAhZvwM+5maR5selACMxK4XBFWAohz8asm4r0hMykMZMs38Uy85jZ++DsqqaFSJFrU1SrRNeLpOmCuU1+XJt46oDhwvhqlRFADmGDRASCYExCaTAGHYmLYE3xkjKHljI4kXS2bwfGIlZZzb+LCQFfpH4739Kq/VkkUL7WUWVBi6ViqFcpgGpWg8ipRbKpNUA5FgOzIthE4FRTL/8aNBXW8zLBIfm0bkCoDLT/RPD8qzDMCeWSyAiSKt1IFTUQolYQRRXyEjlMg2pUm0gxH5h5YxwdgwHyGuSgRydAOFJWRCxNQ9COYJZVOZOEoMrCAjl8CH40yiwDw6CSKH1Cwmp2gD+vUlUuv/E1T/AQlqzhvp82wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": " dev202308 003",
            "title": " dev202308 003",
            "src": "/static/1c73537891936d1153988ab62f33437b/fc2a6/_dev202308_003.png",
            "srcSet": ["/static/1c73537891936d1153988ab62f33437b/5a46d/_dev202308_003.png 300w", "/static/1c73537891936d1153988ab62f33437b/fc2a6/_dev202308_003.png 450w"],
            "sizes": "(max-width: 450px) 100vw, 450px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`EOL を迎えたリソースは公式でのサポート終了を意味するものであるため、以降発生するバグや脆弱性に対しての修正対応が期待できない状態になります。`}</p>
    <p>{`バージョンアップ対応を行わない場合、運良くバグや脆弱性の問題が発生しなければソフトウェア的に動作の継続は可能ですが、これに関連するライブラリなども古いバージョンのサポートを切るタイミングが出てくるので遅かれ早かれ影響は出てきます。`}</p>
    <p>{`また AWS のマネージドサービス (ex.RDS/ElastiCache/OpenSearch) では、サポート期限の終了に伴い強制的に最新バージョンへの更新がスケジュールされます。`}</p>
    <p>{`「最新バージョンへと更新してくれる」、その言葉だけ見ると素晴らしいものですが、もちろん更新中に発生するダウンタイムや接続するアプリケーションに対する影響まで面倒は見てくれません。`}</p>
    <p>{`そのため、マネージドだろうが非マネージドだろうがバージョンアップ対応は必ず行う必要があると言うことです。`}</p>
    <h2>{`作業の内容`}</h2>
    <p>{`この作業は単純にリソースのバージョンアップをするだけで済む作業ではありません。`}</p>
    <p>{`前述でのダウンタイムやバージョン間による変更差分によりアプリケーションが今まで通り動いているかを保証する必要があるため、下記をセットで実施することでシステム稼働を担保しています。`}</p>
    <ul>
      <li parentName="ul">{`新旧バージョンの変更履歴を把握`}</li>
      <li parentName="ul">{`変更によるアプリケーション影響の把握`}</li>
      <li parentName="ul">{`アプリケーションの動作チェック`}</li>
      <li parentName="ul">{`負荷チェック`}</li>
      <li parentName="ul">{`移行計画の策定`}</li>
      <li parentName="ul">{`移行作業の実施`}</li>
    </ul>
    <h2>{`取り組み 1.予測/計画`}</h2>
    <p>{`これらの作業は決して少ない工数では無く、システムやリソースの増加に伴って作業量も増えていく一方です。`}</p>
    <p>{`AWS であれば EOL 通知はメール等で行われるため今までは通知が来てから計画を立てるというフローを採っていましたが、対象システムの増加に伴うリソース増により上記内容を期限内に消化するのが困難になってきています。`}</p>
    <p>{`さらに `}<a parentName="p" {...{
        "href": "https://docs.aws.amazon.com/ja_jp/AmazonRDS/latest/UserGuide/MySQL.Concepts.VersionMgmt.html"
      }}>{`RDS for MySQL`}</a>{` に関してはバージョンあたりのサポート期限が短縮傾向にあるため、一度バージョンを上げればこの先数年は安泰といった状態でも無くなってきています。`}</p>
    <p>{`この状態を踏まえて、2023 年からは通知が来る前に先手を打つ方針に切り替えました。
いつ何が起きるのか？（どれがいつ EOL を迎えるのか？）を事前に把握し、それを元に逆算し計画をすることで無駄の無い行動を目指すというものです。`}</p>
    <h3>{`対象リソースのリストアップ`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "891px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/cebf5d62b3d2891d3db23a102f3a468c/b7877/_dev202308_002a.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "36.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsTAAALEwEAmpwYAAABlklEQVQozzWQyY7aUBBF+f9/yCaLrBJlXkSddBQUA01Dg2ODB0abwRM22H74PdOLE9lJL0q6urp1dKs6w4WGczJ5cH/Tt7vo3iOzwwQrmNKzfqH7I+zEwIr+MFr3MfZjZsG0zTeeFelYoc4smLA6uXQcb84h9VnuHJztHM0Z8NPt0XUH6IsJXrhmfVzgx5tWb4IluvPE6uDi+lbr7xOPXbzhmB7oeFuPWtWUpaDICz5NurwefeOd2WWsTzmnZ9JTiqwkaVaQpCVZmtHs+Z5PkZcoVaOkopIVnd3Ob0VZlggh+Gr0eKPf89Hp0x8PcWybOI5RsiI6XYizilpJsiwjDEN2+x1JkqDUf2Bj3m63FiZKwY95jw/md+4WGoPxA6ZhcDweqWtFei7IS8X1em3zVVX9G1lR1zWVknRWq1VLb4FC8Dh8j6a9Yul+ZjTSMM1520BKxVUIyqvCXJ+Jk4xTkhAEAXme83x7RjbA5pyG/gK8s/q8Ne/5YmsY9owojIiiiEpKhCi5lJLt8cIlL9p2L69qtKgEfwGRawBiV7zyNgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": " dev202308 002a",
            "title": " dev202308 002a",
            "src": "/static/cebf5d62b3d2891d3db23a102f3a468c/b7877/_dev202308_002a.png",
            "srcSet": ["/static/cebf5d62b3d2891d3db23a102f3a468c/5a46d/_dev202308_002a.png 300w", "/static/cebf5d62b3d2891d3db23a102f3a468c/0a47e/_dev202308_002a.png 600w", "/static/cebf5d62b3d2891d3db23a102f3a468c/b7877/_dev202308_002a.png 891w"],
            "sizes": "(max-width: 891px) 100vw, 891px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
まずは各システムが利用するリソースのバージョンと EOL をリストアップし、自分たちが抱えるシステムとリソースの全量とそれらのバージョンがどういった状態なのかを把握します。`}</p>
    <p>{`ここから各バージョンの EOL 時期を収集することになるのですが、幸いバージョン EOL については個々の公式サイトで明記されていることが多いので把握はそれほど難しくはありませんでした。`}</p>
    <p>{`ex.)`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.ruby-lang.org/en/downloads/branches/"
        }}>{`Ruby`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.oracle.com/jp/java/technologies/java-se-support-roadmap.html"
        }}>{`Java`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://docs.aws.amazon.com/ja_jp/AmazonRDS/latest/UserGuide/MySQL.Concepts.VersionMgmt.html"
        }}>{`AWS RDS/MySQL`}</a></li>
    </ul>
    <p>{`AWS といったマネージドサービスに対しては公式の EOL と必ずしもイコールにはなりませんが、マネージドサービス側が公式 EOL より期限が早いことは無いので、公式 EOL を期限として考えるようにしています。`}</p>
    <p>{`また公式サイトが見つからない/明記されていないといったものが稀にあり、それらは `}<a parentName="p" {...{
        "href": "https://endoflife.date/"
      }}>{`endoflife.date`}</a>{` というサイトを活用しました。`}</p>
    <h3>{`優先度の決定`}</h3>
    <p>{`リストアップした時点でかなりの量に及ぶため、ここから対応優先度を決定します。`}</p>
    <p>{`最優先とすべきポイントとしては、期限を迎えると強制的にバージョンアップされてしまうマネージドサービス系のリソースです。`}</p>
    <p>{`これらについては切られた期限に対する猶予は基本与えられないため、優先度が一番高いものと考えます。
次点として EC2 に自前でインストールするようなリソースが続きます。これは最悪 EOL を迎えても動作は継続できるため、上記よりは優先度を下げています。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1018px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/fc43e4954cdb5a74904e33ff1c89cdc4/3dde1/_dev202308_005a.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAABZElEQVQoz21R247TQAzNP/MH/NW+wCNiEfSFSqXNlSjbmS2ZpJPtzt0HedpUFcLSUTyxdY59XOAWl8sFQoiM8/mMxyCijMfQWmOe59wbY6Rbny6cc1iWBcfjEVVVZXA+GQO5LHAxIhEhEiGkhE4pnLRG17Yoqwp1XcMYQzdRXTBZ0zRo2zYXGTzlXik8C4HZmvtUMQb8lBLDPKNrGux2O2w2G2y3WzocDrylLtbmEAL6vs+qx74HUsr//7xZfPzyGx8+lfg+THcLeFUWZiIpJU3TBO+9LlZvjDEoyzKvzP74RPAxYn63+NwpPFUn9NMCioE9u/vIhN771eBMyA8KIVBV11SVJb0qRT+kpG+nE03W5jojUaLnYaCvw5C/r+NITV3TSkhE5+LxglJKdF2XL84z+JSuB0lXcG6I8J4SLBHUNOHXfg/x8sITserCHjoGEbk1/xdc+1/dWuuEEE6No6OrD+NfZRtjo6gEGGAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": " dev202308 005a",
            "title": " dev202308 005a",
            "src": "/static/fc43e4954cdb5a74904e33ff1c89cdc4/3dde1/_dev202308_005a.png",
            "srcSet": ["/static/fc43e4954cdb5a74904e33ff1c89cdc4/5a46d/_dev202308_005a.png 300w", "/static/fc43e4954cdb5a74904e33ff1c89cdc4/0a47e/_dev202308_005a.png 600w", "/static/fc43e4954cdb5a74904e33ff1c89cdc4/3dde1/_dev202308_005a.png 1018w"],
            "sizes": "(max-width: 1018px) 100vw, 1018px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`後はこれらの 優先度/EOL の 2 軸で対応順を検討し、2023 年の年間スケジュールに落とし込みました。`}</p>
    <h3>{`効果`}</h3>
    <p>{`2023 年 8 月の時点で、AWS のマネージドサービス系のリソースについては通知を受ける前にバージョン追従作業を済ませることができています。`}</p>
    <p>{`特に 2023 年 10 月に EOL を迎える RDS の MySQL5.7 → MySQL8.0 対応は大変大きな規模の作業になりましたが、対象 4 サービス、DB インスタンス十数台を 2023 年 7 月に無事すべて切り替えることができました。`}</p>
    <p>{`途中 AWS では OpenSearch や ElastiCache の緊急セキュリティアップデート対応が突発で入りましたが、全ての計画において先手というバッファを見込んでいたため予定を大きく狂わせず完了することができています。`}</p>
    <h2>{`取り組み 2.突発作業の削減`}</h2>
    <p>{`先程は計画を立てて無駄のない行動を目指すことを目的としていましたが、どれだけ予測や計画の精度を上げた所で突発作業はどうしても発生します。`}</p>
    <p>{`社内からの要因であれば対処の仕方もありますが、外部要因となると予測も対応も困難であるものが多くなります。
特に「何も変更していないのに急に動かなくなった！」が一番脅威で、OS 仕様変更や外部ライブラリの廃止に伴うものがチームの実績として多く感じています。
こういった外部要因による事象を発生させにくい状態にするため、以下の対策を行っています。`}</p>
    <h3>{`PrivateGem サーバの構築`}</h3>
    <p>{`ジョブメドレーは Ruby で動くアプリケーションですが、外部ライブラリとして多数の Gem を併用しています。
この Gem がある日突然リポジトリから削除され `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`bundle install`}</code>{` が通らず起動しない、といったことが稀にありました。
バージョンアップで対応できるものであればまだ良いのですが、そもそも廃止といった状況だと対応の長期化は必至。`}</p>
    <p>{`そういった事態を避けるため `}<a parentName="p" {...{
        "href": "https://github.com/geminabox/geminabox"
      }}>{`Geminabox`}</a>{` にて PrivateGem サーバを構築しました。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "497px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/7393d0d27f32741e578819ccda7782bc/15d25/_dev202308_006a.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.666666666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAABq0lEQVQoz41STU+DQBDlx/lzvHrTmx57MfGHePdgbDRpjLW20FppUhs+CrSh7C4ssBSembU0NWp0ks0w2ceb92bWwB/RNI3ORVFApCmSOAabzcDCUNdpmn7BGv8hy/McgnP9XbkusutrlIPBZyO6EwJ1Xev6X4SMMf2DzHMMHh5gdruY9HrwfV/fk0py8CthS9RmpRQ457p2fR/vjoNgtYKUUqujvLdcNw3ac0jUHNQUYRgijmOtJJdSj4FOWZZfxBg/qjsgq6oKQRBgvV5/U94GkdIsCWv4UsIWHK+cQe0G28JpdpvNRtulnGXZ/r7FJEkCnmUo6hoJYzCOnx9xdHeDi4mJmeAauVVqD6bu1JnmSA0oaqW0SloUoxkul0gsC4UQME5GzzgbD3E1m+JpHWEbBOC2jawoEEUR5vM5xuMxHMfBYrHQb09Op8jiGBtSR/PsdhF1Oqg8D8blZITzlz5OX56wzPNPBTvrZJHsrnYb9TwPlmVhNBzCNE24rgtBmChC3O+jVApGyhhYkkByjrrafnvQREgqiZxs05apQWufNv5m27i9v9cv4QNGiEuhRzyJigAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": " dev202308 006a",
            "title": " dev202308 006a",
            "src": "/static/7393d0d27f32741e578819ccda7782bc/15d25/_dev202308_006a.png",
            "srcSet": ["/static/7393d0d27f32741e578819ccda7782bc/5a46d/_dev202308_006a.png 300w", "/static/7393d0d27f32741e578819ccda7782bc/15d25/_dev202308_006a.png 497w"],
            "sizes": "(max-width: 497px) 100vw, 497px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`このサーバは RubyGems 向けの Proxy/キャッシュサーバ という位置づけで、このサーバ経由で RubyGems に Gem を取得すると同時にサーバ内にも Gem はキャッシュします。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "509px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e5ea8b461cdc606280b9708e2d4bdfa7/71554/_dev202308_007a.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.666666666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAABo0lEQVQoz42TzY7TMBSF8za8CXseghVLWPMCXfAEPAPSsGbFAsSmKtMw06plkqot/UvdJHZip3a+kQ0pGQkGrnSVG9/ck3OO7YhHom3bS13XNVIpTvs9YrEgz/Pw3jTNg2+j/wFTSqGkDLUejZBXV9jtFguURYEx5jLzT0DnXGATWFYVu8mE3XjMPklwvm8tRVE8ztA3u/RRVVWQ7AeT5ZKTlPzYbsO6X+vLjlzb0mVfZh/QWst6vQ7SG2MCUy/T/8T3+hH9jV0XWusA5pn8yV/nLLpWGF3Rto4oVZI4P4U8O/cAXAgR/CvLMtR98300jSHLSuoGVA2HgyB6MfzC048fGExiFkrC2WKNwTrHSYjgj0/vl5f8G7BFa8iOnzlM35Ol79hnt0Svroe8vrnmzfSGWBw5r1aUaYrSms1mw2w2I47jUHv5fYbGOFSecJw+oVg+oyhKore33xh8HTIYj8h/7VY35Bl577IsCxvgWfYZegeE+EQyf8569ZLjcU7USIWRCltV4Uz1GYQDrdTPWyLlZUc7QP/IsgXz7yl3dzPyfMc9O4pO5rgmEqAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": " dev202308 007a",
            "title": " dev202308 007a",
            "src": "/static/e5ea8b461cdc606280b9708e2d4bdfa7/71554/_dev202308_007a.png",
            "srcSet": ["/static/e5ea8b461cdc606280b9708e2d4bdfa7/5a46d/_dev202308_007a.png 300w", "/static/e5ea8b461cdc606280b9708e2d4bdfa7/71554/_dev202308_007a.png 509w"],
            "sizes": "(max-width: 509px) 100vw, 509px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`キャッシュされた Gem については以降 RubyGems に再度取りに行くことは無いため、RubyGems 上で取得できない事態が発生しても喫緊の対応が必要な状況にはなりません。`}</p>
    <h3>{`動作環境の Docker イメージ化`}</h3>
    <p>{`とある Python によるプログラムを対象としているのですが OS/Python バージョン/Pip ライブラリ の兼ね合いがとても厳しく、少しでもバージョンにズレが生じると動作不良を起こし対応に回るといったことが年〜半年に一度発生していました。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "770px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c4ae7786760e60e189cffc25547f10a6/f4b77/_dev202308_008.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "28.666666666666668%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsTAAALEwEAmpwYAAABs0lEQVQY0yWLQUhTcQDG37Ug6ZCUB3coEINMEDpLtw5J1yA0kcyVFEgp2LYoUlOKLJQghC4KZVG5ICrdLN2bbsuhFVtu9ma6nBtvburmmm97/1+4ffCDD77fJ122wRW7oMUGLfa9XsRoh0ufc5x5pnD6iZ/axz7qrWlap4rbHgV/Eq5OQuM42FYEktkpMMnQ7RbcmdExyTq3HDrdHjB/zdAwHOLiyDLNL5YxfdnBLOtY5HzB7XLlMU1r3JZzdE7n8CVAOvJAUDkgqHoK9aNpjvbEOPFQ5eSjOA3DEaq7flHTE+BUb5DG5wpVFi+Gmx4qOr00Dfk4fmOCirYJDjV/4L41gCTdhfJ+KOmFcyNZDt+Lc6xvgwMWlbqhdco6vnOwbYH91+epGwxiaP/GPuMsJa0uzvb/wHBtnFLjJ6TzY1wYnEP6uJjlzcI2zt8pHIEEL2cjvHJHmVFSuIIqo84/WOciTPlVvKEE7zx/sbpXcQQ3+bmS5LUjwFvnEu9dIXZzeST/vJvt+BrazhZbGzGiYYVkLAz6Lmp0jbCySDad5F8qCboGaKBlKCZf+Gyq6wgtgxCC/2kBdWwup7A9AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": " dev202308 008",
            "title": " dev202308 008",
            "src": "/static/c4ae7786760e60e189cffc25547f10a6/f4b77/_dev202308_008.png",
            "srcSet": ["/static/c4ae7786760e60e189cffc25547f10a6/5a46d/_dev202308_008.png 300w", "/static/c4ae7786760e60e189cffc25547f10a6/0a47e/_dev202308_008.png 600w", "/static/c4ae7786760e60e189cffc25547f10a6/f4b77/_dev202308_008.png 770w"],
            "sizes": "(max-width: 770px) 100vw, 770px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`動作環境としては`}</p>
    <ul>
      <li parentName="ul">{`プログラム開始時に EC2 起動`}</li>
      <li parentName="ul">{`コードのダウンロード`}</li>
      <li parentName="ul">{`ライブラリのインストール`}</li>
      <li parentName="ul">{`プログラム実行`}</li>
      <li parentName="ul">{`プログラム終了後に EC2 停止`}</li>
    </ul>
    <p>{`という形態を取っていましたが、それぞれのステップに於いて毎度同じ環境になる保証がない状態というのが大きな課題でした。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "770px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f47919e5ffd6780731ef3643730b9526/f4b77/_dev202308_009.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "29.000000000000004%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsTAAALEwEAmpwYAAABrElEQVQY00XLPWsTcQDH8QPFyTdgBRepIA6+Bt+Am+Am1KXaQQcF61CoD6hBxaHSRZwsYlBbaZKSh0svvctDa6kaampsuTbmkvQuyV1yTdJL0v6/Ejv4gw+/6SvdUmBUHhD//kb8v5HwAZemdXz+LDk5yVosw4tYlZuRLgllnULyK5HFHHdj+4zE4JspkCbTgnFV8GgZJtOH3FePPFwGX6rD2HudvKyxHZhl6+MMK0tZ3ihl9NkP/AmHcKLzTEeL3EtBqQ3S0HPBhdeC81Nwzd9m+KnJxZdVzj6rEVjawVvV0ENBCsEAdU3FVBPUtASVyAK6rHBlIsjw7QinRkO8XdxBOv4AzryCk0/g8rsupx/XOOezOTZeZSpo4GUUjLhMPanibfzETicxEwpNLU4o+oMT18MMjYWRrs5xZ2YdaWFjny/ZPdTfLpktB3+mxKeVXbRNl45Vobqt45bLtCyTlmXhlks4RhFaLt/1Gp+1PHPJTSJrBQaTsqspGpZBr91kz6lhGjq2WYTDLka5Qu5XnrrjYDcadPt9vF6PVqeDGNSiT7O+i2tbcOAhhOAv6JqLX5T0fM8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": " dev202308 009",
            "title": " dev202308 009",
            "src": "/static/f47919e5ffd6780731ef3643730b9526/f4b77/_dev202308_009.png",
            "srcSet": ["/static/f47919e5ffd6780731ef3643730b9526/5a46d/_dev202308_009.png 300w", "/static/f47919e5ffd6780731ef3643730b9526/0a47e/_dev202308_009.png 600w", "/static/f47919e5ffd6780731ef3643730b9526/f4b77/_dev202308_009.png 770w"],
            "sizes": "(max-width: 770px) 100vw, 770px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`具体的には EC2 の起動時は Amazon Linux 2 の仕様で Python のバージョンが変わって動かない、ライブラリのインストールではバージョン固定が甘くある日バージョンが変わって動かなくなる、といったことが起こる再現性の低い仕組みで組まれている状態です。`}</p>
    <p>{`そこで、毎回同じ動作環境を維持するための取り組みとして Docker イメージによる環境の固定化を行いました。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "781px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/5914405631cbbb7b28b5e730c117bf5f/7fee5/_dev202308_010a.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "28.666666666666668%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsTAAALEwEAmpwYAAABp0lEQVQY022KT0hTcQDHfyDUpYOHUC8dskNRFy8KHuzQRTC6h2AzgtZ0akVR0pgVYaeQ7NIhogjpUDDCovwz93zb3nOhtlXMzfce6hK1GW5vvrnafO8T2bE+8L18vh9haGkMw2BlySBrFvFOlnEHoSsEF97/ovWxzqnhJCcfJDj9ZBnPFHiCcCkI7sm/nTcE58fh1aKDSKRXWMqsoukG2ZzFLanEgOIwoNj4I7v0jP7A9cKg47lOd2CD26qDP7rLXdXh/keHG1IZX7jCtVAF9TuI40MWHS/zNAxv0z6Sp2XwE9VelborMWr6Yhy5OccxX5wT/gSHrs9S0zdD7eU/i+F+lqJ1MEKTT+Lo1Qm6nsYR9Y/g8EOougdnRmwa78wjzkqIzjDinMy+iwoHPCoHe2cQnfKeEy4Z0T5N29AXGvolaj1j7He9pdkvIwJxkw/JIuPJAqm1baRUHkU3UfQC4XSOsfg67+ZWmfic3XNRzSSyaDK7bJHZtAhENd6oGq/lBQrFEiKjJ/lmLPB1PoZd/sm/VKBSAuz/fDY7hS1ym+tsZdfYKVr8BiVtdeQ1e1NGAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": " dev202308 010a",
            "title": " dev202308 010a",
            "src": "/static/5914405631cbbb7b28b5e730c117bf5f/7fee5/_dev202308_010a.png",
            "srcSet": ["/static/5914405631cbbb7b28b5e730c117bf5f/5a46d/_dev202308_010a.png 300w", "/static/5914405631cbbb7b28b5e730c117bf5f/0a47e/_dev202308_010a.png 600w", "/static/5914405631cbbb7b28b5e730c117bf5f/7fee5/_dev202308_010a.png 781w"],
            "sizes": "(max-width: 781px) 100vw, 781px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`これは Docker イメージによって OS を固定した上でイメージ内にコード/ライブラリまでを含めてしまうもので、OS 起動からプログラムの起動まですべて同じ状態になるようにしました。`}</p>
    <p>{`また副産物としてプログラム起動までのステップが短縮され、プログラム起動までのオーバヘッドの短縮にも繋げることができました。`}</p>
    <h3>{`効果`}</h3>
    <p>{`双方の仕組みの導入により、今年は対象システム回りでの突発作業は発生しない状態を維持できています。`}</p>
    <h1>{`最後に`}</h1>
    <p>{`今回は SRE Unit の業務の一つであるバージョン追従対応についてフォーカスを当てて紹介しましたが、抱える業務は他にも多数あります。`}</p>
    <p>{`我々 SRE Unit の目的はサイトの安定稼働/信頼性を維持することであり、今回のようなバージョンアップはその中の一部でしかありません。この他にも負荷の監視やパフォーマンスチューニング、障害対応などを取り組むべきことは山ほどあります。
まだチームの人数は決して多くは無いこの現状で、いかにアイデア/技術/仕組み化によって効率的に動けるか切磋琢磨し、より安定したサービスを提供できるように日々取り組んでいます。`}</p>
    <p>{`もしこれらの取り組みに興味のある方は、是非我々と一緒に働きませんか？`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/"
      }}>{`https://www.medley.jp/jobs/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      